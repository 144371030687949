<template>
  <section id="main-company">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/main.jpg')"
      height="800"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-subheading
            size="text-h5"
            weight="regular"
            :title="subheading"
          />
          <base-heading
            size="text-h2"
            :title="heading"
            weight="medium"
          />

          <base-body>
            {{body}}
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn
              @click="fnGoToAbout"
            >
              {{discover}}
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition'

  export default {
    name: 'SectionMainCompany',
    provide: {
      theme: { isDark: true },
    },
    data () {
      return {
        videoPath: require('@/assets/main_video.mp4'),
        videoPath480p: require('@/assets/main_video_mobile.mp4'),
        posterPath: require('@/assets/main_poster.jpg'),

      }
    },
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
      subheading () {
        return this.$t('main.subheading')
      },
      heading () {
        return this.$t('main.heading')
      },
      body () {
        return this.$t('main.body')
      },
      discover () {
        return this.$t('main.discover')
      },
    },
    methods: {
      fnGoToAbout () {
        this.$router.push('/about')
      },
    },
  }
</script>
